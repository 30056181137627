import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn, name } from "../services/auth"
import Box from "../components/box"

export const LoggedInPath = `/projects`
export const LoginPath = `/private/login`

class Login extends React.Component {
  state = {
    username: name,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
  }

  render() {
    if (isLoggedIn()) {
      navigate(LoggedInPath)
    }

    return (
      <Box>
        <Helmet>
          <title>Private projects log in</title>
        </Helmet>

        <h1>Private projects log in</h1>

        <p>
          This project requires a password to view.  If you don't have a password, please contact <a href="mailto:contact@ethanensminger.com">Ethan</a>.
        </p>

        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
            navigate(LoggedInPath)
          }}
        >
          <label>
            Password
            <input
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </label>
          <input type="submit" value="Log In" />
        </form>
      </Box>
    )
  }
}

export default Login
