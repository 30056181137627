/** This page has links to projects I want to share but not publicly. */

import React from "react"
import { Router } from "@reach/router"

import CellTracking from "../components/private/cell-tracking"
import FileTransfer from "../components/private/file-transfer"
import PrivateRoute from "../components/privateRoute"
import Login, { LoginPath } from "../components/login"

export default () => (
  <Router>
    <PrivateRoute path="private/cell-tracking" component={CellTracking}/>
    <PrivateRoute path="private/file-transfer" component={FileTransfer}/>
    <Login path={LoginPath}/>
  </Router>
)
