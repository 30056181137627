import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import { LoginPath } from "./login"



const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== LoginPath) {
    navigate(LoginPath)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
