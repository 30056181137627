import React from "react"
import { Helmet } from "react-helmet"
import file from "./file-transfer-project.html"


const FileTransfer = () => (
    <>
        <Helmet>
            <title>File Transfer Project</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{__html: file}} />
    </>
)

export default FileTransfer
