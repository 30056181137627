/**
 * This defines authentication functions.  Right now, we are using hard coded
 * user name and password.
 */

import crypto from "crypto";

export const isBrowser = () => typeof window !== "undefined"
export const name = `private_projects`

const store = isBrowser() ? window.sessionStorage: {};
const userObjectName = "privateProject"

export const getUser = () =>
  isBrowser() && store.getItem(userObjectName)
    ? JSON.parse(store.getItem(userObjectName))
    : {}

const setUser = user =>
  store.setItem(userObjectName, JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  let hash = crypto.createHash("sha512");
  hash.update(password);

  hash = hash.digest('hex');

  const pass = "2aeade4fbf39af96c41631c57bff2279764b5d9edf35786fc9470f48b9b1259097a4584c7a59275cd0d8951604bb962befb155ee35dbe0cfbaf4a5a9e019f259";

  if (username === name &&  hash === pass) {
    return setUser({
      username: name,
    })
  };

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}
