import React from "react"
import { Helmet } from "react-helmet"
import cellTracking from "./cell-tracking-project.html"

const CellTracking = () => (
    <>
        <Helmet>
            <title>Cell Tracking Project</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{__html: cellTracking}} />
    </>
)

export default CellTracking
